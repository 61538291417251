<template>
    <dv-full-screen-container class="bg" style="width: 100%; height: 100%">
        <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" :title="route.query.hotelName" />
        <van-row class="row" :style="{ height: boxH + 'px' }">
            <van-col span="4">
                <ul class="sidebar">
                    <li v-for="(item, index) in typeList" :key="item.text"
                        :class="{ active: active === index ? true : false }" @click="checkli(index)">
                        <span>{{ item.text }}</span>
                    </li>
                </ul>
            </van-col>
            <van-col span="20" style="position:relative;">
                <!-- 布草类型 -->
                <img v-if="showFlag && swipeIndex" @click="clickBack" class="pageBtn" src="@/assets/back.png" alt=""
                    srcset="" style="left: 0.1rem;" />
                <van-swipe @change="onChange" :style="{ height: boxH + 'px' }" ref="swipe" :duration="null">
                    <van-swipe-item v-for="item in swipeList" :key="item">
                        <ul class="content">
                            <li class="hotelItem" v-for="item in hotel_arr" :key="item.name">
                                <div v-if="item != 'covering'" @click="showModal(item)">
                                    <span style="margin:auto;">{{ item.name }}</span>
                                </div>
                            </li>
                        </ul>
                    </van-swipe-item>
                </van-swipe>
                <img v-if="showFlag && swipeIndex != (swipeList - 1)" @click="clickNext" class="pageBtn"
                    src="@/assets/next.png" alt="" srcset="" style="right: 0.1rem;" />
                <!-- 布草弹窗 -->
                <van-dialog v-model:show="show" width="800px" show-cancel-button :before-close="beforeClose"
                    @confirm="checkConfirm" @cancel="show = false">
                    <template v-slot:title style="padding: 0">
                        <div
                            style="display:flex;justify-content: space-evenly;padding: 0.2rem;border-bottom: 1px solid #ccc;">
                            <span>类型：{{ types }}</span>
                            <span>规格：{{ boxVal }}</span>
                            <span>数量：{{ boxNum }}</span>
                        </div>
                    </template>

                    <div v-if="!modalFlag"
                        style="
    padding: 0 2rem;padding-bottom: 0.2rem;max-height: 500px;
    overflow: auto;">
                        <p style="margin:0.2rem;">选择规格</p>
                        <van-button @click="checkBox(item)" class="box" type="primary" v-for="item in boxList"
                            :key="item.code">{{ item.scale }}
                        </van-button>
                    </div>
                    <div v-if="modalFlag"
                        style="padding-bottom: 0.2rem;justify-content: center;display: flex;flex-flow: column;">
                        <p style="margin:0.2rem;">选择数量</p>
                        <van-button @click="checkNum(10)" type="primary" class="box">10</van-button>
                        <van-button @click="checkNum(20)" type="primary" class="box">20</van-button>
                        <van-button @click="checkNum(50)" type="primary" class="box">50</van-button>
                        <van-button v-model="customNum" @click="keypadModal = true" class="box">{{ customVal }}
                        </van-button>
                    </div>
                </van-dialog>
                <!-- 布草数量小键盘弹窗 -->
                <van-dialog v-model:show="keypadModal">
                    <p class="numInput" :value="customNum">{{ customVal1 }}</p>
                    <ul class="keypad">
                        <li @click="clickNum(i)" v-for="i in keypadList" :key="i">
                            {{ i != 'back' || i != 'del' ? i : '' }}
                        </li>
                        <li @click="clickNum('del')"
                            style="position: absolute;width: 101px;right: 0.2rem;padding: 0.7rem 0;border-bottom: 0;background-color: #fff;">
                            删除
                        </li>
                    </ul>
                </van-dialog>
                <!-- 打包成功弹窗 -->
                <van-dialog v-model:show="packModal">
                    <p style="margin:0.2rem;">打包成功</p>
                    <div style="text-align: center;">
                        <p class="title"><span>客户：</span>{{ route.query.hotelName }}</p>
                        <p class="title"><span>布草类型：</span>{{ packInfo.bucao_name }}</p>
                        <p class="title"><span>规格：</span>{{ packInfo.bucao_size }}</p>
                        <p class="title"><span>数量：</span>{{ packInfo.bucao_count }}</p>
                    </div>
                </van-dialog>
            </van-col>
        </van-row>
    </dv-full-screen-container>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRoute } from 'vue-router'
import { Icon, NavBar, TreeSelect, Col, Row, Swipe, SwipeItem, Toast, Dialog, Button, Step, Steps, Field, CellGroup, Notify } from 'vant';
import api from "../../../../utils/api";
// import api from "@/utils/api";
export default {
    // name: "factory_deskpack",
    components: {
        [Icon.name]: Icon,
        [NavBar.name]: NavBar,
        [Col.name]: Col,
        [Row.name]: Row,
        [TreeSelect.name]: TreeSelect,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Button.name]: Button,
        [Step.name]: Step,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
        [Steps.name]: Steps,
        [Dialog.Component.name]: Dialog.Component,
        [Notify.Component.name]: Notify.Component,
        [Toast.name]: Toast,
    },
    setup() {
        const activeIndex = ref(0);
        const hotel = ref([])
        const hotel_arr = ref([])
        const boxH = ref(0)
        const route = useRoute()
        const swipeList = ref(0)
        const swipeIndex = ref(0)
        const show = ref(false)
        const packModal = ref(false)
        const keypadModal = ref(false)//小键盘弹窗
        const packInfo = ref()
        const boxList = ref([])
        const types = ref('')
        const boxVal = ref('')
        const boxNum = ref('')
        const modalFlag = ref(0)
        const fieldvalue = ref('')
        const bucao_code = ref('')
        const loading = ref(false)
        const customNum = ref('')
        const hotelAllList = ref([])//所有分类
        const bedArr = ref([])//床类
        const towelArr = ref([])//巾类
        const customVal = ref('自定义打包数量')//自定义按钮文本
        const customVal1 = ref('请输入自定义打包数量')
        const { proxy } = getCurrentInstance()
        const showFlag = ref(false)
        // const _this= instance.appContext.config.globalProperties
        // 屏幕高度
        const windowHeight = ref(0)
        const active = ref(0)
        const stepsActive = ref(0)



        /* 轮播组件change事件 */
        const onChange = (index) => {
            swipeIndex.value = index
            hotel_arr.value = hotel.value.slice(index * 9, (index + 1) * 9)
            if (hotel_arr.value.length < 9) {
                let num = 9 - hotel_arr.value.length
                for (let i = 0; i < num; i++) {
                    hotel_arr.value.push('covering')
                }
            }
            // Toast('当前 Swipe 索引：' + (index + 1))
        };
        /* 点击返回按钮 */
        const onClickLeft = () => {
            history.back()
        }
        /* 点击切换样式 */
        const checkli = (i) => {
            console.log(i)
            active.value = i
            proxy.$refs.swipe.swipeTo(0)
            switch (i) {
                case 0:
                    console.log("0")
                    hotel.value = hotelAllList.value
                    onChange(0)
                    break;
                case 1:
                    console.log("1")
                    hotel.value = bedArr.value
                    onChange(0)
                    break;
                case 2:
                    console.log("2")
                    hotel.value = towelArr.value
                    onChange(0)
                    break;
            }
            swipeList.value = hotel.value.length > 9 ? Math.ceil(hotel.value.length / 9) : 1

        }
        /* 获取酒店布草类型 */
        const getHotelLinenType = () => {
            api.getHotelLinenType(route.query.hotelCode).then(data => {
                const res = data.data
                if (res.code == 200) {
                    if (res.data.length) {
                        bedArr.value = []
                        towelArr.value = []
                        hotelAllList.value = res.data
                        hotel.value = res.data
                        swipeList.value = hotel.value.length > 9 ? Math.ceil(hotel.value.length / 9) : 1
                        onChange(0)//首次加载第一页内容
                        res.data.map(item => {
                            if (item.type) {
                                bedArr.value.push(item)
                            } else {
                                towelArr.value.push(item)
                            }
                        })
                        console.log(swipeList.value)
                    } else {
                        Dialog.alert({
                            title: '提示',
                            message: '还未给该客户配置布草',
                        }).then(() => {
                            onClickLeft()
                        });
                        console.log("back")
                    }
                }
            })
        }
        /* 点击弹窗 */
        const showModal = (item) => {
            console.log("333")
            types.value = item.name
            boxVal.value = ''
            boxNum.value = ''
            modalFlag.value = 0
            customNum.value = ''
            customVal.value = '自定义打包数量'
            customVal1.value = '请输入自定义打包数量'
            show.value = true
            getHotelLinenSize(route.query.hotelCode, item.code)
        }
        /* 获取酒店布草规格 */
        const getHotelLinenSize = (code, category) => {
            api.getHotelLinenSize(code, category).then(data => {
                const res = data.data
                if (res.code == 200) {
                    console.log("111")
                    boxList.value = res.data
                    if (res.data.length == 1) {
                        checkBox(res.data[0])
                    }
                }
            })
        }
        /* 点击包装规格按钮 */
        const checkBox = (item) => {
            boxVal.value = item.scale
            bucao_code.value = item.code
            modalFlag.value = 1
        }
        /* 点击包装数量 */
        const checkNum = (num) => {
            boxNum.value = num
            uploadPack()
        }
        /* 点击确定按钮 */
        const checkConfirm = () => {
            if (customNum.value) {
                uploadPack(customNum.value)
            } else {
                Dialog.alert({
                    title: "提示",
                    message: "请输入自定义数量或选择默认数量",
                })
            }
        }
        /* 弹窗异步回调 */
        const beforeClose = (action) => {
            new Promise((resolve) => {
                // setTimeout(() => {
                if (action === 'confirm') {
                    resolve(true);
                } else {
                    // 拦截取消操作
                    resolve(true);
                }
                // }, 1000);
            });
        }
        /* 布草无芯片打包 */
        const uploadPack = (count) => {
            api.uploadPack({
                app_code: null,
                app_scene: null,
                device_code: route.query.device_code,
                bucao_code: bucao_code.value,
                count: count ? parseInt(count) : boxNum.value,
                staff_code: route.query.staff_code,
                staff_type: route.query.staff_type
            }).then(data => {
                const res = data.data
                if (res.code == 200) {
                    console.log('success')
                    packInfo.value = res.data
                    show.value = false
                    packModal.value = true
                } else {
                    console.log(res)
                    Dialog.alert({
                        title: "错误提示",
                        message: res.message,
                    })
                }
            })
        }
        /* 点击小键盘 */
        const clickNum = (i) => {
            if (customVal1.value == '请输入自定义打包数量') {
                customVal1.value = ''
            }
            if (customVal.value == '自定义打包数量') {
                customVal.value = ''
            }
            if (i == 'back' || i == 'del') {
                customVal.value = '自定义打包数量'
                customVal1.value = '请输入自定义打包数量'
            } else {
                customVal1.value = customVal1.value + i
                customVal.value = customVal.value + i
                customNum.value = customVal.value
            }
        }
        /* 上一页/下一页 */
        const clickBack = () => {
            proxy.$refs.swipe.prev()
        }
        const clickNext = () => {
            proxy.$refs.swipe.next()
        }


        // 生命周期
        onMounted(() => {
            show.value = false
            windowHeight.value = window.innerHeight//获取屏幕高度
            // window.addEventListener('resize', getWindowResize)
            setTimeout(() => {
                console.log(document.getElementsByClassName('van-nav-bar__content')[0].clientHeight)
                let n = document.getElementsByClassName('van-nav-bar__content')[0].clientHeight
                boxH.value = windowHeight.value - n
                showFlag.value = true
            }, 500)
            getHotelLinenType()//获取酒店布草类型

        })

        return {
            hotel,
            boxH,
            active,
            stepsActive,
            swipeList,
            swipeIndex,
            hotel_arr,
            route,
            boxList,
            show,
            activeIndex,
            typeList: [{ text: '全部', icon: '' }, { text: '床类', icon: '' }, { text: '巾类', icon: '' }],
            keypadList: [1, 2, 3, '', 4, 5, 6, '', 7, 8, 9, 0],
            Toast,
            types,
            boxVal,
            boxNum,
            fieldvalue,
            modalFlag,
            packModal,
            packInfo,
            loading,
            customNum,
            keypadModal,
            customVal,
            customVal1,
            showFlag,
            clickBack,
            clickNext,
            onClickLeft,
            onChange,
            showModal,
            checkli,
            getHotelLinenType,
            checkBox,
            checkNum,
            uploadPack,
            checkConfirm,
            beforeClose,
            clickNum,
        }
    },
};
</script>
<style lang="less" scoped>
.bg {
    background-color: rgb(236, 236, 236);
}

.row {
    background-color: #fff;
}

p {
    margin: 0;
}

.box {
    margin: 0.1rem auto;
    width: 2rem;
}

.content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0.2rem 0.6rem;
    box-sizing: border-box;
}

.sidebar li {
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.15rem;
    border-right: 1px solid #EBEDF0;
    cursor: pointer;
}


.active {
    background-color: #E6F7FF;
    position: relative;
}

.active::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background-color: #4095E5;
    right: 0;
}

.title {
    text-align: left;
    width: 60%;
    margin: 0.1rem auto;
}

.hotelItem {
    width: 33.33%;
    height: 33.33%;

    div {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #4095E5;
        color: #fff;
        font-size: 0.3rem;
        border-radius: 0.05rem;
    }

    box-sizing: border-box;
    padding: 0.2rem;
}

.boxclass {
    padding: 0.1rem;
    border: 1px solid #ccc;
    border-radius: 0.03rem;
}

.keypad {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0.2rem 0.2rem 0.2rem;
    box-sizing: border-box;
    border-left: 1px solid #ccc;

    li {
        box-sizing: border-box;
        width: 25%;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 0.3rem;
    }
}

.pageBtn {
    position: absolute;
    width: 0.5rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 99;
}

.numInput {
    border: 1px solid #ccc;
    margin: 0.2rem;
    padding: 0.1rem;
    margin-bottom: 0;
}

/deep/ .van-swipe__indicator {
    background-color: #333;

}

/deep/ .van-dialog__header {
    padding-top: 0;
    // font-weight: 500;
    // line-height: 0.26667rem;
    // text-align: center;
}

/deep/ .van-dialog__content {
    max-height: 600px !important;
    overflow: auto;
}
</style>

